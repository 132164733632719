import { gql } from "@apollo/client";

const profile = `
  email
  adjectives
  ethnicity
  firstName
  lastName
  challenges
  challengesOther
  company
  companyPosition
  confidence {
    question
    answer
  }
  goals
  onboardedAt
  personas
  profilePicture
  lastOnBoardingRoute
  yearsInWork
  slackEmail
  wordpressCreated
  profileTimezone
`;

export const upsertProfile = gql`
  mutation UpsertProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      ${profile}
    }
  }
`;

export const updateProfileAdjectives = gql`
  mutation UpdateProfileAdjectives($input: UpdateProfileAdjectivesInput!) {
    updateProfileAdjectives(input: $input) {
      ${profile}
    }
  }
`;

export const updateProfileChallenges = gql`
  mutation UpdateProfileChallenges($input: UpdateProfileChallengesInput!) {
    updateProfileChallenges(input: $input) {
      ${profile}
    }
  }
`;

export const updateProfileConfidence = gql`
  mutation UpdateProfileConfidence($input: UpdateProfileConfidenceInput!) {
    updateProfileConfidence(input: $input) {
      ${profile}
    }
  }
`;

export const updateProfileDemographics = gql`
  mutation UpdateProfileDemographics($input: UpdateProfileDemographicsInput!) {
    updateProfileDemographics(input: $input) {
      ${profile}
    }
  }
`;

export const updateProfileGoals = gql`
  mutation UpdateProfileGoals($input: UpdateProfileGoalsInput!) {
    updateProfileGoals(input: $input) {
      ${profile}
    }
  }
`;

export const updateProfileLastOnBoardingRoute = gql`
  mutation UpdateProfileDemographics($input: UpdateProfileLastOnBoardingRouteInput!) {
    updateProfileLastOnBoardingRoute(input: $input) {
      ${profile}
    }
  }
`;

export const updateProfilePersonas = gql`
  mutation updateProfilePersonas($input: UpdateProfilePersonasInput!) {
    updateProfilePersonas(input: $input) {
      ${profile}
    }
  }
`;

export const updateProfilePicture = gql`
  mutation UpdateProfilePicture($input: UpdateProfilePictureInput!) {
    updateProfilePicture(input: $input) {
      ${profile}
    }
  }
`;
