import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  HttpLink
} from "@apollo/client";
import { onError } from "apollo-link-error";
import { createAuthLink } from "aws-appsync-auth-link";

import Profile from "./profile";

const auth = {
  type: "API_KEY",
  apiKey: process.env.REACT_APP_AWS_API_KEY
};

const authLink = createAuthLink({
  url: process.env.REACT_APP_AWS_API_URL,
  region: process.env.REACT_APP_AWS_REGION,
  auth
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_AWS_API_URL
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (process.env.NODE_ENV === "development") {
        console.log(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
        );
      }
    }
  }

  if (networkError && process.env.NODE_ENV === "development")
    console.log(`[Network error]: ${networkError}`);
});
const link = ApolloLink.from([authLink, onErrorLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default function AppProviders({ children }) {
  return (
    <ApolloProvider client={client}>
      <Profile.ProfileProvider email={window.localStorage.getItem("email")}>
        {children}
      </Profile.ProfileProvider>
    </ApolloProvider>
  );
}
