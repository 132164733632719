import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import Profile from "Providers/profile";

function Routes() {
  const location = useLocation();
  const { updateProfileLastOnBoardingRoute } = Profile.useProfile();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const testing = urlParams.get('testing');

    if (!["/", "/sign-up"].includes(location.pathname) && !testing)
      updateProfileLastOnBoardingRoute({
        variables: {
          input: {
            email: window.localStorage.getItem("email"),
            lastOnBoardingRoute: location.pathname
          }
        }
      });
  }, [location, updateProfileLastOnBoardingRoute]);

  return (
    <React.Suspense fallback={null}>
      <Switch>
        {appRoutes.map(({ path, component: Component }, i) => {
          return <Route key={i} exact path={path} component={Component} />;
        })}

        <Route exact path="/ui-kit" component={UIKit} />
        <Route exact path="/styleguide" component={StyleGuide} />
      </Switch>
    </React.Suspense>
  );
}

export default Routes;

const appRoutes = [
  { path: "/confirm", component: React.lazy(() => import("./Confirm")) },
  {
    path: "/demographics",
    component: React.lazy(() => import("./Demographics"))
  },
  {
    path: "/elements/:element",
    component: React.lazy(() => import("./Elements"))
  },
  {
    path: "/events",
    component: React.lazy(() => import("./Events"))
  },
  { path: "/intro", component: React.lazy(() => import("./Intro")) },
  {
    path: "/modules/:module",
    component: React.lazy(() => import("./Modules"))
  },
  {
    path: "/profile-picture",
    component: React.lazy(() => import("./ProfilePicture"))
  },
  {
    path: "/questions/1",
    component: React.lazy(() => import("./Question1"))
  },
  { path: "/questions/2", component: React.lazy(() => import("./Question2")) },
  { path: "/questions/3", component: React.lazy(() => import("./Question3")) },
  { path: "/questions/4", component: React.lazy(() => import("./Question4")) },
  { path: "/questions/5", component: React.lazy(() => import("./Question5")) },
  { path: `/sign-up`, component: React.lazy(() => import("./SignUp")) },
  { path: `/slack`, component: React.lazy(() => import("./Slack")) },
  { path: `/`, component: React.lazy(() => import("./Landing")) }
];

const UIKit = React.lazy(() => import("./UIKit"));
const StyleGuide = React.lazy(() => import("./StyleGuide"));
