import React from "react";
import { useMutation, useQuery } from "@apollo/client";

import { getProfile } from "graphql/queries";
import { updateProfileLastOnBoardingRoute } from "graphql/mutations";

const ProfileContext = React.createContext();

function ProfileProvider(props) {
  const { email } = props;

  const {
    client,
    data: { getProfile: profile } = {},
    refetch: fetchProfile
  } = useQuery(getProfile, {
    fetchPolicy: "network-only",
    variables: { email },
    skip: !email
  });
  const [updateProfile] = useMutation(updateProfileLastOnBoardingRoute);

  const value = React.useMemo(
    () => ({
      client,
      fetchProfile,
      profile,
      updateProfileLastOnBoardingRoute: updateProfile
    }),
    [client, fetchProfile, profile, updateProfile]
  );

  return <ProfileContext.Provider value={value} {...props} />;
}

function useProfile() {
  const context = React.useContext(ProfileContext);

  if (context === undefined) {
    throw new Error("useUI must be used inside its provider.");
  }

  return context;
}

const Profile = { ProfileProvider, useProfile };

export default Profile;
