import { gql } from "@apollo/client";

const profile = `
  email
  adjectives
  ethnicity
  firstName
  lastName
  challenges
  challengesOther
  company
  companyPosition
  confidence {
    question
    answer
  }
  goals
  onboardedAt
  personas
  profilePicture
  lastOnBoardingRoute
  yearsInWork
  slackEmail
  wordpressCreated
  profileTimezone
`;

export const getProfile = gql`
  query GetProfile($email: AWSEmail!) {
    getProfile(email: $email) {
      ${profile}
    }
  }
`;
